import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarModule, MatSnackBarRef } from "@angular/material/snack-bar";

export class AlertError {
	private static snackBar: MatSnackBar;

	public static setSnackBar(snackBar: MatSnackBar) {
		this.snackBar = snackBar;
	}

	public static showError(err) {
		console.error(err);
		let _icon = "⚠️";
		let _title = "ERROR";
		let _message = "Se ha producido un error desconocido.";
		let _duration = 5000;

		switch (err.status) {
			case 400: {
				_title = "ERROR 400";
				_message = "Se ha producido un error en la llamada al servidor.";
				break;
			}
			case 401: {
				_title = "ERROR 401";
				_icon = "🚫";
				_message = "No tienes acceso al recurso solucitado.";
				break;
			}
			case 403: {
				_title = "ERROR 403";
				_icon = "🚫";
				_message = "Acceso denegado.";
				break;
			}
			case 404: {
				_title = "ERROR 404";
				_message = "No existe el recurso solicitado.";
				break;
			}
			case 409: {
				_title = "ERROR 409";
				_message = "Se ha producido un conflicto con la solicitud del servidor.";
				break;
			}
			case 500: {
				_title = "ERROR 500";
				_icon = "❌";
				if (err?.error?.code) {
					_message = "Se ha producido un error con código " + err.error.code + ".\nContacte con el equipo de soporte para solucionar el problema.";
					_duration = 600000;
				} else {
					_message = "Error interno del servidor.";
				}
				break;
			}
			default: {
				break;
			}
		}

		this.snackBar.openFromComponent(ErrorSnackbarComponent, {
			data: {
				title: _title,
				message: _message,
				icon: _icon,
			},
			duration: _duration,
			panelClass: [err.status == 500 ? "error-snackbar" : "warn-snackbar"],
		});
	}
}

@Component({
	selector: "error-snack",
	template: `
		<div class="container">
			<span class="icon">{{ data.icon }}</span>
			<div>
				<h2 class="title">{{ data.title }}</h2>
				<p class="msg">{{ data.message }}</p>
			</div>
			<button class="btn" (click)="snackBarRef.dismiss()">X</button>
		</div>
	`,
	styles: [
		`
			.container {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
			.icon {
				font-size: 24px !important;
				margin-right: 12px;
			}
			.title {
				margin: 0;
				font-weight: bold;
				text-align: center;
			}
			.msg {
				margin: 0;
			}
			.btn {
				background: none;
				color: inherit;
				border: none;
				padding: 0;
				font: inherit;
				cursor: pointer;
				outline: inherit;
				font-weight: bold;
				margin-left: 12px;
				font-size: 16px;
			}
		`,
	],
})
export class ErrorSnackbarComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
		public snackBarRef: MatSnackBarRef<ErrorSnackbarComponent>,
	) {}
}
