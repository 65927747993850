export const environment = {
	production: true,
	hmr: false,
	firebase: {
		apiKey: "AIzaSyBUrwB84X1IDUBa58qRoQvS-DsIQ42IPkI",
		authDomain: "ifeedback365-app.firebaseapp.com",
		databaseURL: "https://ifeedback365-app.firebaseio.com",
		projectId: "ifeedback365-app",
		storageBucket: "ifeedback365-app.appspot.com",
		messagingSenderId: "1038355768571",
		appId: "1:1038355768571:web:cfd8a473d4de83b1497c5e",
	},
	clientSecret: "MzUzYjMwMmM0NDU3NGY1NjUwNDU2ODdlNTM0ZTdkNmE6Mjg2OTI0Njk3ZTYxNWE2NzJhNjQ2YTQ5MzU0NTY0NmM=",

	urlDomain: "https://ws-feedbalia-uat.aitanasolutions.com", //LOCAL,
	// urlDomain: 'https://wsnew.feedbalia.com', //DEMO,
	// urlDomain: 'https://wstwo.feedbalia.com', // PRODUCCION

	// urlDomainImages: 'http://localhost:3000', //LOCALs
	// urlDomainImages: 'https://emprepolis.feedbalia.com', //FEEDBALIA
	urlDomainImages: "https://ws-feedbalia-uat.aitanasolutions.com", //PRODUCCION
	// urlDomainImages: 'https://sermas365.feedbalia.com/', //LEROY

	// urlDomainEvaluationFiles: 'http://localhost:3000/static/files', //LOCAL
	urlDomainEvaluationFiles: "https://ws-feedbalia-uat.aitanasolutions.com/static/files", //PRODUCCION
	// urlDomainEvaluationFiles: 'https://sermas365.feedbalia.com/javax.faces.resource/static/files/', //LEROY
	pageSize: 10,
};
