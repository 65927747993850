import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";
import { BehaviorSubject, Observable, of } from "rxjs";

import { environment } from "../../../environments/environment";

import { Token } from "app/shared/models/user.interface";
import { catchError, map } from "rxjs/operators";
import { AlertError } from "../error-handler";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	public entorno = new BehaviorSubject<string>("WEB");

	constructor(private http: HttpClient, public router: Router) {
		this.checkToken();
	}

	setEntorno() {
		if (window.location.href.includes("/administracion/")) {
			this.entorno.next("ADMIN");
		} else {
			this.entorno.next("WEB");
		}
	}

	isUserLoggedIn(): boolean {
		return this.checkToken();
	}

	isAdmin(): Observable<boolean> {
		const token = JSON.parse(localStorage.getItem("tokenUser"));

		if (!token) return of(false);

		const tokenData = JSON.parse(atob(token.access_token.split(".")[1]));

		const accessToken = this.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = `${environment.urlDomain}/api/v1/persons/canAccessAdministration/${tokenData.projectId}/${tokenData.userId}`;

		return this.http.get(url, options).pipe(
			map((response: boolean) => {
				return response;
			}),
			catchError((error) => {
				AlertError.showError(error);
				return of(false);
			})
		);
	}

	isSuperAdmin(): Observable<boolean> {
		const token = JSON.parse(localStorage.getItem("tokenUser"));

		if (!token) return of(false);

		const tokenData = JSON.parse(atob(token.access_token.split(".")[1]));

		const accessToken = this.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = `${environment.urlDomain}/api/v1/persons/isSuperAdmin/${tokenData.userId}/${tokenData.projectId}`;

		return this.http.get(url, options).pipe(
			map((response: boolean) => {
				return response;
			}),
			catchError((error) => {
				AlertError.showError(error);
				return of(false);
			})
		);
	}

	getAccessToken(): string {
		const stringToken = localStorage.getItem("tokenUser");
		let accessToken = "";

		if (stringToken !== null) {
			const token = JSON.parse(stringToken);
			accessToken = token.access_token;
		}

		return accessToken;
	}

	rememberPassword(email: string): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			const body = {
				email: email,
				// 'hostname': document.location.hostname
			};
			const options = {
				headers: new HttpHeaders({
					Authentication: "Basic " + environment.clientSecret,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.post(environment.urlDomain + "/oauth/recoverPassword", body, options).subscribe(
				(response: any) => {
					// console.log("remember password", response);
					observer.next(true);
				},
				(err) => {
					console.log("error login", err);
					if (err.status == 404) {
						//Si no se encuentra el usuario, se considera como que ha ido bien la llamada, para no dar pistas de los correos de los usuarios
						observer.next(true);
					} else {
						let msgError = "No se ha podido enviar el email.";
						if (err.error.error === "invalid_username") {
							msgError = "Debe indicar el usuario";
						}

						observer.error(msgError);
					}
				}
			);
		});
	}

	validateHashPassword(userId: number, date: string, hash: string): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			const body = {
				userId: userId,
				date: date,
				hash: hash,
			};
			const options = {
				headers: new HttpHeaders({
					Authentication: "Basic " + environment.clientSecret,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.post(environment.urlDomain + "/oauth/validateHash", body, options).subscribe(
				(response: any) => {
					this.saveToken(response, false);
					observer.next(true);
				},
				(err) => {
					console.log("error validate hash", err);
					this.router.navigate(["login"], { replaceUrl: true, queryParams: { returnUrl: this.router.url } });
					observer.next(false);
					let msgError = "No se ha podido enviar el email.";

					// observer.error(msgError);
				}
			);
		});
	}

	login(username: string, password: string): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			const body = {
				username: username,
				password: password,
				grant_type: "password",
			};
			const options = {
				headers: new HttpHeaders({
					Authentication: "Basic " + environment.clientSecret,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.post(environment.urlDomain + "/oauth/token", body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error login", err);
					let msgError = "Se ha producido un error";
					if (err.error && err.error.code) {
						const codeError = err.error.code;
						if (codeError === "L001" || codeError === "L002") {
							msgError = "Usuario o contraseña incorrectos";
						} else if (codeError === "L003") {
							msgError = "Usuario bloqueado";
						} else if (err.error.message) {
							msgError = err.error.message;
						}
					}

					observer.error(msgError);
				}
			);
		});
	}

	loginSocial(email, emailToken = "") {
		return new Observable<boolean>((observer) => {
			const body = {
				email: email,
				email_token: emailToken,
				grant_type: "email",
			};
			const options = {
				headers: new HttpHeaders({
					Authentication: "Basic " + environment.clientSecret,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.post(environment.urlDomain + "/oauth/token", body, options).subscribe(
				(response: any) => {
					this.saveToken(response, false);
					observer.next(true);
				},
				(err) => {
					console.log("error login social", err);
					let msgError = "No se ha podido realizar el inicio de sesión";

					observer.error(msgError);
				}
			);
		});
	}

	saveToken(token: Token, readOnlyMode: boolean): void {
		let now = moment();
		let dateExpired = now.toDate().getTime() + token.expires_in;
		token.dateExpired = dateExpired;
		const tokenString = JSON.stringify(token);
		if (readOnlyMode) {
			const _oldToken = localStorage.getItem("tokenUser");
			localStorage.setItem("oldToken", _oldToken);
			localStorage.setItem("tokenUser", tokenString);
		} else {
			localStorage.setItem("tokenUser", tokenString);
		}
	}

	hasOldToken(): boolean {
		const token = JSON.parse(localStorage.getItem("tokenUser"));
		const oldToken = localStorage.getItem("oldToken");
		return token.readOnlyMode && oldToken ? true : false;
	}

	logout(): void {
		localStorage.removeItem("tokenUser");
	}

	private checkToken(): boolean {
		let validToken = false;
		const stringToken = localStorage.getItem("tokenUser");

		if (stringToken !== null) {
			// console.log("token", JSON.parse(stringToken));
			const token = JSON.parse(stringToken);
			if (token.access_token && token.expires_in) {
				const now = moment();
				const dateExpired = moment(token.dateExpired);

				// console.log("now", now);
				// console.log("expire", dateExpired);

				if (now.isBefore(dateExpired)) {
					validToken = true;
				} else {
					validToken = false;
				}
			} else {
				validToken = false;
			}
		} else {
			validToken = false;
		}

		return validToken;
	}

	getPermisos(rol: string): Observable<any> {
		const accessToken = this.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
			params: { rol },
		};

		const url = environment.urlDomain + "/api/v1/user/checkRol";
		return this.http.get(url, options).pipe(
			map((response: any) => response),
			catchError((error) => {
				AlertError.showError(error);
				throw error;
			})
		);
	}

	getPermisosWeb(rol: string): Observable<any> {
		const accessToken = this.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
			params: { rol },
		};

		const url = environment.urlDomain + "/api/v1/user/checkRolWeb";
		return this.http.get(url, options).pipe(
			map((response: any) => response),
			catchError((error) => {
				AlertError.showError(error);
				throw error;
			})
		);
	}

	getPermisosAdmin(): Observable<any> {
		const accessToken = this.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = environment.urlDomain + "/api/v1/user/checkAdminRol";
		return this.http.get(url, options).pipe(
			map((response: any) => response),
			catchError((error) => {
				AlertError.showError(error);
				throw error;
			})
		);
	}
}
