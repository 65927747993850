import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class HelpersService {
	constructor() {}

	serializeData(data) {
		if (typeof data !== "object") {
			return data == null ? "" : data.toString();
		}

		let buffer = [];

		for (let name in data) {
			if (!data.hasOwnProperty(name)) {
				continue;
			}
			let value = data[name];
			buffer.push(encodeURIComponent(name) + "=" + encodeURIComponent(value == null ? "" : value));
		}

		let source = buffer.join("&").replace(/%20/g, "+");
		return source;
	}
}
